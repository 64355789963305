import React, { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import { AuthService } from "../../services/authService";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { UilTimes } from "@iconscout/react-unicons";

type Props = {};

const OtpConfirmation = (props: Props) => {
  const [otp, setOtp] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isResending, setResend] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [enteredEmail, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (!email) {
      setNoEmail(true);
    }
  }, []);

  const userEmail = localStorage.getItem("email") ?? enteredEmail;

  const handleEmailConfirm = async () => {
    if (enteredEmail) {
      await AuthService.getOtp();
      localStorage.setItem("email", enteredEmail);
      setEmailConfirmed(true);
      setNoEmail(false);
    } else {
      toast.error("Please enter a valid email.");
    }
  };

  const handleConfirm = async () => {
    const email = localStorage.getItem("email") || enteredEmail;
    setSubmitting(true);

    if (!email) {
      toast.error("No email found in local storage.");
      setSubmitting(false);
      setNoEmail(true);
      return;
    }

    try {
      await AuthService.confirmOtp({ email, otp }).then((res: any) => {
        if (res.data.status === -1) {
          toast.error(res.data.message);
          return;
        }

        toast.success(res.data.message);
        localStorage.removeItem("email");
        navigate("/dashboard");
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while confirming OTP."
      );
      if (error.response.status === 401) {
        navigate("/auth");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    if (isResending) {
      return;
    }
    setResend(true);
    try {
      const response = await AuthService.getOtp();
      if (response && response.message) {
        toast.success("OTP sent successfully!");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while resending OTP."
      );
    } finally {
      setResend(false);
    }
  };

  useEffect(() => {
    const requiresVerification = searchParams.get("requires_verification");
    if (requiresVerification) {
      setRequiresVerification(true);
    }
  }, [searchParams]);

  return (
    <div className="col-md-8 mx-auto p-3 d-flex flex-column align-items-center gap-3">
      <img
        src="../assets/images/dark.png"
        alt="nufiSMS logo"
        style={{ height: "100px" }}
      />
      {requiresVerification && (
        <div
          className="alert p-2 alert-danger alert-dismissible fade show col-md-8 d-flex justify-content-between align-items-center"
          role="alert"
        >
          <span>
            You should <strong>verify</strong> your account to access the
            platform
          </span>
          <button
            type="button"
            className="close btn"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <UilTimes />
            </span>
          </button>
        </div>
      )}
      <div className="card stretch stretch-full col-md-8 mx-auto">
        <div className="card-body d-flex flex-column gap-3">
          <h3 className="text-center">Verify Email Address</h3>
          {noEmail && !emailConfirmed && (
            <div className="d-flex flex-column gap-3">
              <input
                type="email"
                name="email"
                id="email"
                value={enteredEmail}
                className="form-control"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="btn btn-primary col-md-3 mx-auto"
                onClick={handleEmailConfirm}
                disabled={!enteredEmail}
              >
                Confirm Email
              </button>
            </div>
          )}
          {(!noEmail || emailConfirmed) && (
            <>
              <p className="text-black text-center">
                A verification code has been sent to your email address (
                {userEmail}). Enter the code below to verify that it's you.
              </p>
              <OTPInput
                value={otp}
                onChange={(code: any) => setOtp(code)}
                numInputs={6}
                renderSeparator={<span className=""></span>}
                containerStyle={"d-flex justify-content-center flex-wrap"}
                inputStyle={"mx-2 p-0 form-control otp-input mb-1"}
                renderInput={(props) => <input {...props} />}
              />
              <button
                className="btn btn-primary col-md-3 mx-auto"
                onClick={handleConfirm}
                disabled={submitting || (noEmail && !enteredEmail)}
              >
                {submitting && <span className="loader"></span>}
                Confirm
              </button>
            </>
          )}
          <div className="d-flex justify-content-center">
            <span>Didn't receive the email?</span>
            <span
              className="text-primary cursor-pointer"
              onClick={handleResendOtp}
            >
              {isResending && <span className="loader"></span>}
              {isResending ? "Resending..." : "Resend"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpConfirmation;
