import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  UilAngleRightB,
  UilAngleDown,
  UilSunset,
  UilTimes,
} from "@iconscout/react-unicons";
import { useAuth } from "../auth/AuthContext";
import { menuItems } from "../../data/MenuItems";

type SideBarProps = {
  isMiniMenu: boolean;
  onToggleMenu: () => void;
};

const SideBar: React.FC<SideBarProps> = ({ isMiniMenu, onToggleMenu }) => {
  const location = useLocation();
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null);

  const { isAllowed } = useAuth();
  const toggleSubMenu = (index: number) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index);
  };

  const isActive = (path: string) => {
    return location.pathname === `/${path}` || location.pathname === path;
  };

  const isSubMenuActive = (subMenuItems: { path: string }[]) => {
    return subMenuItems.some((subItem) =>
      location.pathname.includes(subItem.path)
    );
  };

  const filteredMenuItems = menuItems.filter((item) => isAllowed(item.path));

  return (
    <nav className="sidebar bg-white">
      <div className="navbar-wrapper">
        <div className="m-header">
          <Link className="b-brand mx-auto" to="/">
            {isMiniMenu ? (
              <img
                src="../assets/images/nufiLogo.png"
                alt=""
                className="logo logo-sm"
                style={{ width: "50px" }}
              />
            ) : (
              <img
                src="../assets/images/dark.png"
                alt=""
                className="logo logo-lg"
                style={{ height: "70px" }}
              />
            )}
          </Link>
        </div>
        <div className="navbar-content">
          <ul className="nxl-navbar list-group">
            <li className="nxl-item nxl-caption fw-bold d-flex justify-content-between align-items-center">
              <label className="text-primary fs-10">NAVIGATION</label>
              {!isMiniMenu && (
                <div
                  className="text-black cursor-pointer"
                  onClick={onToggleMenu}
                >
                  <UilTimes />
                </div>
              )}
            </li>
            {filteredMenuItems.map((menuItem, index) => (
              <li
                key={index}
                className={`nxl-item nxl-hasmenu ${
                  isActive(menuItem.path) ||
                  isSubMenuActive(menuItem.subMenu || [])
                    ? "active"
                    : ""
                }`}
              >
                {menuItem.subMenu ? (
                  <div
                    className={`nxl-link cursor-pointer ${
                      isActive(menuItem.path) ||
                      isSubMenuActive(menuItem.subMenu || [])
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleSubMenu(index)}
                  >
                    <span className="nxl-micon">{menuItem.icon}</span>
                    <span className="nxl-mtext">{menuItem.label}</span>
                    <span className="nxl-arrow float-right">
                      {openSubMenuIndex === index ? (
                        <UilAngleDown />
                      ) : (
                        <UilAngleRightB />
                      )}
                    </span>
                  </div>
                ) : (
                  <Link
                    to={menuItem.path}
                    className={`nxl-link ${
                      isActive(menuItem.path) ? "active" : ""
                    }`}
                  >
                    <span className="nxl-micon">{menuItem.icon}</span>
                    <span className="nxl-mtext">{menuItem.label}</span>
                  </Link>
                )}
                {menuItem.subMenu && openSubMenuIndex === index && (
                  <ul
                    className={`nxl-submenu ${isMiniMenu ? "d-none" : ""} ${
                      openSubMenuIndex === index ? "open" : ""
                    }`}
                  >
                    {menuItem.subMenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`nxl-item ${
                          isActive(subItem.path) ? "active" : ""
                        }`}
                      >
                        {subItem.path.startsWith("http") ? (
                          <Link
                            className="nxl-link"
                            to={subItem.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {subItem.label}
                          </Link>
                        ) : (
                          <Link
                            className={`nxl-link ${
                              isActive(subItem.path) ? "active" : ""
                            }`}
                            to={subItem.path}
                          >
                            {subItem.label}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="card text-center">
            <div className="card-body">
              <UilSunset />
              <h6 className="mt-4 text-dark fw-bolder">Stay Connected</h6>
              <p className="fs-11 my-3 text-dark">
                Flexible integration of professional SMS
              </p>
              <Link to="whatsapp" className="btn btn-primary text-dark w-100">
                Send Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
