import axiosInstance from "./axiosInstance";

const getUserApiKeys = async () => {
  const response = await axiosInstance.get(`/nufisms-api/user/keys`);
  return response.data;
};

const generateApiKeys = async (data: {
  phoneNumber: string;
  ipAddress?: string;
}) => {
  const response = await axiosInstance.post("/nufisms-api/key/create", data);
  return response.data;
};

const getAllApiAddresses = async () => {
  const response = await axiosInstance.get(`/nufisms-api/whitelist/all`);
  return response.data;
};

const whitelistIp = async (data: { ip: string }) => {
  const response = await axiosInstance.post(`/nufisms-api/whitelist`, data);
  return response.data;
};

const enableIp = async (ipAddress: string) => {
  const response = await axiosInstance.post(
    `/nufisms-api/whitelist/enabled/${ipAddress}`
  );
  return response.data;
};

const disableIp = async (ipAddress: string) => {
  const response = await axiosInstance.post(
    `/nufisms-api/whitelist/desabled/${ipAddress}`
  );
  return response.data;
};

export const ApiService = {
  getUserApiKeys,
  generateApiKeys,
  getAllApiAddresses,
  enableIp,
  disableIp,
  whitelistIp,
};
