import React from "react";
import { UilTrashAlt } from "@iconscout/react-unicons";
import moment from "moment";

export type Session = {
  id: number;
  phoneNumber: string;
  initializedAt: string;
  status: string;
};

type UserWhatsappSessionsProps = {
  sessions: Session[];
  handleReuseSession: (session: Session) => void;
};

const UserWhatsappSessions: React.FC<UserWhatsappSessionsProps> = ({
  sessions,
  handleReuseSession,
}) => {
  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "not initialized":
        return "bg-soft-warning text-warning";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };
  return (
    <div className="row note-has-grid" id="note-full-container">
      {sessions.map((session, index) => (
        <div
          key={index}
          className="col-xxl-4 col-xl-6 col-lg-4 col-sm-6 single-note-item all-category note-important note-tasks"
        >
          <div className="card card-body mb-4 stretch stretch-full">
            <span className="side-stick"></span>
            <h5
              className="note-title text-truncate w-75 mb-1"
              data-noteheading={session.phoneNumber}
            >
              {session.phoneNumber}
              <span
                className={`badge ${getStatusBadgeClass(session.status)} ms-2`}
              >
                {session.status === "not initialized" ? "Not Active" : "Active"}
              </span>
            </h5>
            <p className="fs-11 text-muted note-date">
              Initialized on the{" "}
              {moment(session.initializedAt).format("DD/MM/YYYY HH:mm")}
            </p>
            <div className="d-flex align-items-center gap-1">
              <span className="avatar-text avatar-sm">
                <UilTrashAlt className="feather-trash-2 icon-sm remove-note text-danger" />
              </span>
              <div className="ms-auto">
                <button
                  onClick={() => handleReuseSession(session)}
                  className="btn btn-primary"
                >
                  reUse
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserWhatsappSessions;
