import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { accountService } from "../../services/accountService";
import { Recharge } from "../../models/Recharges";

interface AccountState {
  balance: number | null;
  recharges: Recharge[]; // Add this to store recharges
  errors: string | null;
  isLoading: boolean;
}

const initialState: AccountState = {
  balance: null,
  recharges: [],
  errors: null,
  isLoading: false,
};

// Async thunks
export const fetchBalance = createAsyncThunk<number>(
  "account/fetchBalance",
  async () => {
    const response = await accountService.getBalance();
    return response.data;
  }
);

export const topUpBalance = createAsyncThunk(
  "account/topUpBalance",
  async (amount: number, { dispatch }) => {
    const response = await accountService.topUpBalance({ amount });
    dispatch(fetchBalance()); // Refresh balance after topping up
    return response;
  }
);

export const confirmPayment = createAsyncThunk(
  "account/confirmPayment",
  async (data: any, { dispatch }) => {
    const response = await accountService.confirmPayment(data);
    dispatch(fetchBalance());
    dispatch(fetchRecharges());
    return response;
  }
);

export const fetchRecharges = createAsyncThunk<Recharge[]>(
  "account/fetchRecharges",
  async () => {
    const response = await accountService.getRecharges();
    return response.data.data;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Balance
      .addCase(fetchBalance.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.errors = action.error.message || "Failed to fetch balance";
        state.isLoading = false;
      })
      // Top Up Balance
      .addCase(topUpBalance.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(topUpBalance.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(topUpBalance.rejected, (state, action) => {
        state.errors = action.error.message || "Failed to top up balance";
        state.isLoading = false;
      })
      // Confirm Payment
      .addCase(confirmPayment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(confirmPayment.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(confirmPayment.rejected, (state, action) => {
        state.errors = action.error.message || "Failed to confirm payment";
        state.isLoading = false;
      })
      // Fetch Recharges
      .addCase(fetchRecharges.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(fetchRecharges.fulfilled, (state, action) => {
        state.recharges = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRecharges.rejected, (state, action) => {
        state.errors = action.error.message || "Failed to fetch recharges";
        state.isLoading = false;
      });
  },
});

export default accountSlice.reducer;
