import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { SMS, SMSStatus } from "../../models/SMS";
import { getAllSms } from "../../redux/slices/smsSlice";
import { UilArrowLeft, UilArrowCircleRight } from "@iconscout/react-unicons";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { AppDispatch } from "../../redux/store";
import { getStatusBadgeClass } from "../../utils/functions";

const SmsHistoric: React.FC = () => {
  const [messages, setMessages] = useState<SMS[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<SMS | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filters, setFilters] = useState<{
    content?: string;
    status?: SMSStatus;
  }>({});

  const dispatch: AppDispatch = useDispatch();

  const fetchSms = useCallback(async () => {
    try {
      const res: any = await dispatch(
        getAllSms({
          page: currentPage,
          limit: itemsPerPage,
          filters,
        })
      ).unwrap();
      setMessages(res.status === -1 ? [] : res.data);
      setTotalPages(Math.ceil(res.total / itemsPerPage));
    } catch (err) {
      console.error("Failed to fetch messages:", err);
    }
  }, [dispatch, currentPage, itemsPerPage, filters]);

  useEffect(() => {
    fetchSms();
  }, [fetchSms]);

  const truncateContent = (content: string, maxLength: number) =>
    content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;

  const handleRowClick = (message: SMS) => setSelectedMessage(message);

  const handleBackClick = () => setSelectedMessage(null);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const renderContactsTable = (contacts: any[]) => (
    <table className="table table-hover mb-0">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact, index) => (
          <tr key={index}>
            <td>{contact.firstname || "N/A"}</td>
            <td>{contact.lastname || "N/A"}</td>
            <td>{contact.email || "N/A"}</td>
            <td>{contact.phone}</td>
            <td>
              <span
                className={`badge ${
                  contact.status
                    ? "bg-soft-success text-success"
                    : "bg-soft-danger text-danger"
                }`}
              >
                {contact.status ? "Sent" : "Not Sent"}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content">
              <div className="d-flex p-3 mb-2 w-100 justify-content-end align-items-center">
                <span>Items per page:</span>
                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="ms-2"
                >
                  {[5, 10, 20, 30, -1].map((item) => (
                    <option key={item} value={item}>
                      {item === -1 ? "All" : item}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row">
                <div className="col-xxl-8">
                  <div className="card stretch stretch-full">
                    {selectedMessage ? (
                      <>
                        <div className="card-header">
                          <button
                            onClick={handleBackClick}
                            className="btn border-0 bg-transparent"
                          >
                            <UilArrowLeft /> Back
                          </button>
                          <h5 className="card-title text-center">
                            SMS Details
                          </h5>
                        </div>
                        <div className="card-body">
                          <h6>SMS Code: {selectedMessage.code_msg}</h6>
                          <div className="d-flex align-items-center mb-3 gap-2">
                            <h6 className="m-0">SMS Content: </h6>
                            <p className="m-0">{selectedMessage.smsContent}</p>
                          </div>
                          <h6>Contacts</h6>
                          <div className="table-responsive">
                            {renderContactsTable(
                              selectedMessage.contactsJsonParsed
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card-header">
                          <h5 className="card-title">SMS History</h5>
                        </div>
                        <div className="card-body custom-card-action p-0">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>SMS Code</th>
                                  <th>Content</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Scheduled Send Time</th>
                                  <th>Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {messages.length > 0 ? (
                                  messages.map((message: SMS) => (
                                    <tr key={message.id}>
                                      <td>{message.code_msg}</td>
                                      <td>
                                        {truncateContent(
                                          message.smsContent,
                                          20
                                        )}
                                      </td>
                                      <td>
                                        {moment(message.timestamp).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge ${getStatusBadgeClass(
                                            message.status as SMSStatus
                                          )}`}
                                        >
                                          {message.status}
                                        </span>
                                      </td>
                                      <td>
                                        {message.isScheduled
                                          ? moment(message.sendTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            handleRowClick(message)
                                          }
                                          className="bg-transparent border-0 ms-1"
                                        >
                                          <UilArrowCircleRight className="text-secondary fs-2" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No SMS records found.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SmsHistoric;
