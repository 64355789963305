import { configureStore } from "@reduxjs/toolkit";
import { contactsSlice } from "./slices/contactSlice";
import contactGroupeSlice from "./slices/contactGroupSlice";
import authSlice from "./slices/authSlice";
import smsSlice from "./slices/smsSlice";
import packageSlice from "./slices/packageSlice";
import accountSLice from "./slices/accountSlice";
import whatsappSlice from "./slices/whatsappSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    contacts: contactsSlice.reducer,
    groups: contactGroupeSlice,
    sms: smsSlice,
    packages: packageSlice,
    account: accountSLice,
    whatsapp: whatsappSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
