import React, { useEffect, useState } from "react";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import AuthLayout from "../../components/auth/AuthLayout";
import { UilPlus, UilAngleLeftB, UilEdit } from "@iconscout/react-unicons";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Package } from "../../models/Package";
import { useDispatch, useSelector } from "react-redux";
import {
  createPackage,
  editPackage,
  getAllPackages,
} from "../../redux/slices/packageSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../redux/store";

type Props = {};

const PricingPlans = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const { packages } = useSelector((state: RootState) => state.packages);

  const handleFormSubmit = (data: Package) => {
    try {
      setIsLoading(true);
      const action =
        isEditMode && selectedPackage
          ? editPackage({ data, code: selectedPackage.package_code as string })
          : createPackage(data);
      dispatch(action as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(
            isEditMode
              ? "Package updated successfully!"
              : "Package created successfully!"
          );
          formik.resetForm();
          setSelectedPackage(null);
          setIsEditMode(false);
        })
        .catch((error: any) => {
          toast.error(
            `Failed to ${isEditMode ? "update" : "create"} Package: ${
              error.message
            }`
          );
        });
    } catch (error) {
      toast.error(
        `An error occurred while ${
          isEditMode ? "updating" : "creating"
        } package. Please try again later`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPackage = (data: Package) => {
    setSelectedPackage(data);
    setIsEditMode(true);
    formik.setValues(data);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      package_code: "",
      unit_price: 0,
      totalExtractions: 0,
      price: 0,
      totalMessages: 0,
      totalWhatsAppNumbers: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      price: Yup.number().required("Required"),
      totalMessages: Yup.number().required("Required"),
      totalWhatsAppNumbers: Yup.number().required("Required"),
      description: Yup.string().max(
        128,
        "Description must be at most 128 characters"
      ),
    }),
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    dispatch(getAllPackages())
      .unwrap()
      .catch((err) => {
        console.error(err);
        toast.error(
          "An error occurred while fetching packages. Please try again later."
        );
      });
  }, [dispatch]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">WhatsApp Packages</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#addPlanModal"
                      className="btn btn-primary"
                      onClick={() => {
                        setIsEditMode(false);
                        formik.resetForm();
                      }}
                    >
                      <UilPlus />
                      <span>Add Package</span>
                    </button>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <span className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row note-has-grid" id="note-full-container">
                <h5>
                  Total packages :{" "}
                  <span className="badge bg-primary text-white ms-2">
                    {packages?.length}
                  </span>
                </h5>
                {packages?.map((pkg, index) => (
                  <div
                    key={index}
                    className="col-xxl-4 col-xl-6 col-lg-4 col-sm-6 single-note-item all-category note-important note-tasks"
                  >
                    <div className="card card-body mb-4 stretch stretch-full">
                      <span className="side-stick"></span>
                      <div className="d-flex justify-content-between mb-4">
                        <h5
                          className="note-title text-truncate text-primary w-75"
                          data-noteheading={pkg.name}
                        >
                          {pkg.name.toUpperCase()}
                        </h5>
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#addPlanModal"
                          onClick={() => handleSelectPackage(pkg)}
                          className="text-warning fw-bold"
                        >
                          Edit <UilEdit />
                        </span>
                      </div>
                      <ul className="list-unstyled activity-feed">
                        <li className="d-flex justify-content-between feed-item feed-item-success">
                          <span className="fw-bold">Total Messages:</span>
                          <span className="fw-bold text-black">
                            {pkg.totalMessages}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between feed-item feed-item-info">
                          <span className="fw-bold">Package Price:</span>
                          <span className="fw-bold text-black">
                            €{pkg.price} /{" "}
                            <em className="fs-11 fw-medium">Month</em>
                          </span>
                        </li>
                        <li className="d-flex justify-content-between feed-item feed-item-warning">
                          <span className="fw-bold">
                            Total WhatsApp Contacts:
                          </span>
                          <span className="fw-bold text-black">
                            {pkg.totalWhatsAppNumbers}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between feed-item feed-item-danger">
                          <span className="fw-bold">
                            Total WhatsApp Extractions:
                          </span>
                          <span className="fw-bold text-black">
                            {pkg.totalExtractions}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between feed-item feed-item-primary">
                          <span className="fw-bold">Package Description:</span>
                          <span className="fw-bold text-black">
                            {pkg.description}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>

        {/* create/edit plan modal*/}
        <div
          className="modal fade"
          id="addPlanModal"
          tabIndex={-1}
          aria-labelledby="addPlanModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {isEditMode ? (
                    <>
                      Edit{" "}
                      <span className="text-primary">
                        {selectedPackage?.name}
                      </span>{" "}
                      package
                    </>
                  ) : (
                    "Add a Whatsapp Package"
                  )}
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSelectedPackage(null)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3 col-12">
                    <label htmlFor="name">Package Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Package Name"
                      id="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="package_code">Pacakge Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pacakge Code"
                      id="package_code"
                      {...formik.getFieldProps("package_code")}
                    />
                    {formik.touched.package_code &&
                    formik.errors.package_code ? (
                      <div className="text-danger">
                        {formik.errors.package_code}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="totalWhatsAppNumbers">
                      Total WhatsApp Numbers
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total WhatsApp Numbers"
                      id="totalWhatsAppNumbers"
                      {...formik.getFieldProps("totalWhatsAppNumbers")}
                    />
                    {formik.touched.totalWhatsAppNumbers &&
                    formik.errors.totalWhatsAppNumbers ? (
                      <div className="text-danger">
                        {formik.errors.totalWhatsAppNumbers}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="totalMessages">Total Message to Send</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total Message to Send"
                      id="totalMessages"
                      {...formik.getFieldProps("totalMessages")}
                    />
                    {formik.touched.totalMessages &&
                    formik.errors.totalMessages ? (
                      <div className="text-danger">
                        {formik.errors.totalMessages}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="totalExtractions">
                      Total Contacts Extraction
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total Contacts Extraction"
                      id="totalExtractions"
                      {...formik.getFieldProps("totalExtractions")}
                    />
                    {formik.touched.totalExtractions &&
                    formik.errors.totalExtractions ? (
                      <div className="text-danger">
                        {formik.errors.totalExtractions}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="price">Price (EURO)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      id="price"
                      {...formik.getFieldProps("price")}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div className="text-danger">{formik.errors.price}</div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="unit_price">Unit Price (EURO)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Unit Price"
                      id="unit_price"
                      {...formik.getFieldProps("unit_price")}
                    />
                    {formik.touched.unit_price && formik.errors.unit_price ? (
                      <div className="text-danger">
                        {formik.errors.unit_price}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-12">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Type a Description"
                      maxLength={128}
                      id="description"
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setSelectedPackage(null)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoading || !formik.isValid}
                  data-bs-dismiss="modal"
                >
                  {isLoading && <span className="loader"></span>}
                  {isEditMode ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default PricingPlans;
