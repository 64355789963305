import { SMSStatus } from "../models/SMS";

export const getStatusBadgeClass = (status: SMSStatus) => {
  switch (status) {
    case "Partially_delivered":
      return "bg-soft-warning text-warning";
    case "Pending":
      return "bg-soft-info text-info";
    case "Sent":
      return "bg-soft-success text-success";
    case "Failed":
      return "bg-soft-danger text-danger";
    default:
      return "bg-soft-secondary text-secondary";
  }
};
