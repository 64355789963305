import React, { useState } from "react";
import { UilCopy, UilEye, UilEyeSlash } from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import { Api } from "../models/ApiKeys";

type UserApiKeysProps = {
  apis: Api[];
};

const UserApiKeys: React.FC<UserApiKeysProps> = ({ apis }) => {
  // State to track visibility of secret key for each API key
  const [showSecret, setShowSecret] = useState<boolean[]>(
    apis.map(() => false)
  );

  // Toggle visibility of the secret key for a specific index
  const toggleSecretVisibility = (index: number) => {
    setShowSecret((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };
  const handleCopy = (key: string) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        toast.success("Copied to clipboard!", {
          style: {
            border: "1px solid #28a745",
            background: "#d4edda",
            color: "#155724",
          },
          iconTheme: {
            primary: "#28a745",
            secondary: "#d4edda",
          },
        });
      })
      .catch((err) => {
        toast.error("Failed to copy! Please try again.", {
          style: {
            border: "1px solid #dc3545",
            background: "#f8d7da",
            color: "#721c24",
          },
          iconTheme: {
            primary: "#dc3545",
            secondary: "#f8d7da",
          },
        });
      });
  };
  return (
    <div className="">
      {apis.map((api, index) => (
        <div key={index} className="card stretch stretch-full">
          <div className="card-header">
            <h5 className="card-title">
              API keys for sender number :{" "}
              <span className="text-secondary">
                {api.userWhatsAppNumber.phoneNumber}
              </span>
            </h5>
          </div>
          <div className="w-100 card-body">
            <div className="mb-3">
              <label className="fw-bold fs-14 text-black mb-1" htmlFor="pk">
                Public Key
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Public Key"
                  id="pk"
                  readOnly
                  value={api.public_key}
                />
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleCopy(api.public_key)}
                >
                  <UilCopy />
                </button>
              </div>
            </div>
            <div className="mb-3">
              <label className="fw-bold fs-14 text-black mb-1" htmlFor="sk">
                Secret Key
              </label>
              <div className="input-group">
                <input
                  type={showSecret[index] ? "text" : "password"} // Toggle between password and text
                  className="form-control"
                  placeholder="Secret Key"
                  id="sk"
                  readOnly
                  value={api.public_key}
                />
                <button
                  className="input-group-text text-primary"
                  onClick={() => toggleSecretVisibility(index)}
                >
                  {showSecret[index] ? <UilEyeSlash /> : <UilEye />}
                </button>
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleCopy(api.public_key)}
                >
                  <UilCopy />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserApiKeys;
