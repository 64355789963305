import React from "react";
import { UilSetting, UilEllipsisV } from "@iconscout/react-unicons";
import PhoneInput from "react-phone-number-input";

type CreateSessionProps = {
  currentStep: number;
  isLoading: boolean;
  existingSession: boolean;
  isScanning: boolean;
  phoneNumber: string | undefined;
  base64Url: string | undefined;
  setPhoneNumber: (number: string | undefined) => void;
  handleInitializeSession: () => void;
  handleBackStep: () => void;
};

const CreateSession: React.FC<CreateSessionProps> = ({
  currentStep,
  isLoading,
  phoneNumber,
  setPhoneNumber,
  existingSession,
  isScanning,
  base64Url,
  handleInitializeSession,
  handleBackStep,
}) => {
  return (
    <div className="card mx-auto mt-3">
      <div className="card-body">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6">
            <h5 className="text-muted">Connect your WhatsApp:</h5>
            <ul className="list-unstyled mt-3 activity-feed">
              <li className="feed-item feed-item-danger">
                Enter your WhatsApp phone number and click <strong>Next</strong>
              </li>
              <li className="feed-item feed-item-warning">
                Open WhatsApp on your phone
              </li>
              <li className="feed-item feed-item-primary">
                Tap Menu <UilEllipsisV /> or Settings <UilSetting /> and select{" "}
                <strong>Linked Devices</strong>
              </li>
              <li className="feed-item feed-item-info">
                Tap on <strong>Link a Device</strong>
              </li>
              <li className="feed-item feed-item-success">
                Point your phone to this screen to capture the QR code
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            {currentStep === 0 && (
              <div>
                {isLoading && (
                  <div className="alert alert-info mb-3">
                    <p className="m-0">
                      Initializing session. This operation may take some time.
                      Please be patient.
                    </p>
                  </div>
                )}
                <h5>Enter Sender Phone Number:</h5>
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  defaultCountry="CM"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="form-control"
                />
                <button
                  disabled={isLoading || !phoneNumber}
                  className="btn btn-primary mt-3 mx-auto"
                  onClick={handleInitializeSession}
                >
                  {isLoading && <span className="loader"></span>}{" "}
                  {isLoading ? "Loading..." : "Next"}
                </button>
              </div>
            )}
            {currentStep === 1 && !existingSession && (
              <div className="step-content d-flex flex-column justify-content-center align-items-center gap-3 my-2">
                <div>
                  <h6>Scan the QR Code to Connect to WhatsApp:</h6>
                  <p className="text-center">
                    This QR code will expire after 45 seconds
                  </p>
                </div>
                <div className="qr-placeholder">
                  {isScanning && (
                    <div className="qr-overlay">
                      <div className="qr-spinner"></div>
                      <div className="qr-text">Logging in...</div>
                    </div>
                  )}
                  {base64Url ? (
                    <img
                      src={base64Url}
                      alt="Scan this QR code with WhatsApp"
                      style={{
                        opacity: isScanning ? 0.5 : 1,
                      }} // Optional: Dim the QR code when scanning
                    />
                  ) : (
                    "QR Code Not Available"
                  )}
                </div>

                <div className="d-flex mx-auto gap-2">
                  <button
                    onClick={handleBackStep}
                    className="btn btn-light-brand"
                    disabled={isScanning}
                  >
                    Back
                  </button>
                  {/* <button
              onClick={() => setSessionInit(true)}
              className="btn btn-light-brand"
            >
              Next
            </button> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSession;
