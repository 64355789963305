import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilAngleLeftB,
  UilCheckCircle,
  UilPlus,
  UilTimesCircle,
  UilAlignRight,
} from "@iconscout/react-unicons";
import { ApiService } from "../../services/apiService";
import toast from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type Props = {};

type IpAddress = {
  id: number;
  ip: string;
  isWhitelisted: boolean;
};

const ManageIpAddresses: React.FC = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [ipAddresses, setIpAddresses] = useState<IpAddress[]>([]);
  const [ip, setIp] = useState<string>("");

  const handleWhiteList = async () => {
    try {
      setLoading(true);
      await ApiService.whitelistIp({ ip });
      toast.success("IP whitelisted successfully");
      fetchIpAddresses();
    } catch (error) {
      toast.error("Error whitelisting IP.");
    } finally {
      setLoading(false);
    }
  };

  const fetchIpAddresses = async () => {
    try {
      setLoading(true);
      const res = await ApiService.getAllApiAddresses();
      if (res.status === -1) {
        toast.error(res.message || "An error occured, please try again later");
        return;
      }
      setIpAddresses(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while fetching IP addresses. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIpAddresses();
  }, []);

  const renderIpRow = (ipAddress: IpAddress) => (
    <div key={ipAddress.id} className="hstack gap-3 justify-content-between">
      <div className="hstack gap-3">
        <div
          className={`wd-7 ht-7 rounded-circle ${
            ipAddress.isWhitelisted ? "bg-success" : "bg-danger"
          }`}
        ></div>
        <div
          className={`ps-3 border-start border-3 rounded ${
            ipAddress.isWhitelisted ? "border-success" : "border-danger"
          }`}
        >
          <span className="fw-semibold mb-1 text-truncate-1-line">
            {ipAddress.ip}
          </span>
          <span className="fs-12 text-muted">
            {ipAddress.isWhitelisted ? (
              <UilCheckCircle className="fs-10 me-1 text-success" />
            ) : (
              <UilTimesCircle className="fs-10 me-1 text-danger" />
            )}
            <span className="fw-normal">
              {ipAddress.isWhitelisted ? "Whitelisted" : "Not Whitelisted"}
            </span>
          </span>
        </div>
      </div>
      <div className="hstack gap-2">
        <div className="avatar-text avatar-md" title="Last checked 12:30 AM">
          <i className="feather-clock"></i>
        </div>
        <span className="avatar-text avatar-md">
          <i className="feather-arrow-right"></i>
        </span>
      </div>
    </div>
  );

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Manage Ip addresses</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#addModal"
                      className="btn btn-primary"
                    >
                      <UilPlus />
                      <span>Add New</span>
                    </button>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <UilAlignRight />
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="">
                  <div className="card stretch stretch-full">
                    <div className="card-header">
                      <div className="card-header-action">
                        <div className="card-header-btn">
                          <div data-bs-toggle="tooltip" title="Delete">
                            <span
                              className="avatar-text avatar-xs bg-danger"
                              data-bs-toggle="remove"
                            >
                              {" "}
                            </span>
                          </div>
                          <div data-bs-toggle="tooltip" title="Refresh">
                            <span
                              className="avatar-text avatar-xs bg-warning"
                              data-bs-toggle="refresh"
                            >
                              {" "}
                            </span>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            title="Maximize/Minimize"
                          >
                            <span
                              className="avatar-text avatar-xs bg-success"
                              data-bs-toggle="expand"
                            >
                              {" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body custom-card-action">
                      {loading ? (
                        <div className="p-3">
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton variant="rectangular" height={60} />
                          </Stack>
                        </div>
                      ) : (
                        ipAddresses.map((ipAddress) => (
                          <React.Fragment key={ipAddress.id}>
                            {renderIpRow(ipAddress)}
                            <hr className="border-dashed my-3" />
                          </React.Fragment>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div
          className="modal fade"
          id="addModal"
          tabIndex={-1}
          aria-labelledby="addModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  WhiteList an Ip address
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-4">
                  <label className="fw-bold fs-14 text-black mb-1" htmlFor="ip">
                    IP Address
                  </label>
                  <input
                    type="text"
                    name="ip"
                    id="ip"
                    placeholder="IP Address"
                    className="form-control"
                    value={ip}
                    onChange={(e) => setIp(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleWhiteList}
                  disabled={loading}
                  data-bs-dismiss="modal"
                >
                  {loading && <span className="loader"></span>}
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ManageIpAddresses;
