import React from "react";
import { UilExclamationTriangle } from "@iconscout/react-unicons";

type BillingProps = {};

const Billing: React.FC<BillingProps> = () => {
  return (
    <div className="tab-pane fade" id="billingTab" role="tabpanel">
      <div
        className="alert alert-dismissible m-4 p-4 d-flex alert-soft-teal-message"
        role="alert"
      >
        <div className="me-4 d-none d-md-block">
          <UilExclamationTriangle />
        </div>
        <div>
          <p className="fw-bold mb-1 text-truncate-1-line">
            We need your attention!
          </p>
          <p className="fs-12 fw-medium text-truncate-1-line">
            Your payment was declined. To start using tools, please{" "}
            <strong>Add Payment Method</strong>
          </p>
          <button className="btn btn-sm bg-soft-teal text-teal d-inline-block">
            Add Payment Method
          </button>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="subscription-plan px-4 pt-4">
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <h5 className="fw-bold mb-0">Subscription & Plan:</h5>
          <span className="btn btn-sm btn-light-brand">4 days remaining</span>
        </div>
        <div className="p-4 mb-4 d-xxl-flex d-xl-block d-md-flex align-items-center justify-content-between gap-4 border border-dashed border-gray-5 rounded-1">
          <div>
            <div className="fs-14 fw-bold text-dark mb-1">
              User current plan is{" "}
              <span className="badge bg-primary text-white ms-2">
                Team Plan
              </span>
            </div>
            <div className="fs-12 text-muted">A simple start for everyone</div>
          </div>
          <div className="my-3 my-xxl-0 my-md-3 my-md-0">
            <div className="fs-20 text-dark">
              <span className="fw-bold">$29.99</span> /{" "}
              <em className="fs-11 fw-medium">Month</em>
            </div>
            <div className="fs-12 text-muted mt-1">
              Billed Monthly / Next payment on 12/10/2023 for{" "}
              <strong className="text-dark">$62.48</strong>
            </div>
          </div>
          <div className="hstack gap-3">
            <span className="text-danger">Cancel Plan</span>
            <span className="btn btn-light-brand">Update Plan</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
